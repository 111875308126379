<template>
  <div class="available-variation bg-cl-primary p0 mb10">
    <div class="row">
      <div class="col-xs-12 center-xs title-container p10 cl-primary-orange">
        <span class="uppercase py5">{{ $t('Dostępne opcje') }}</span>
        <div @click="$store.commit('ui/setAvailableVariation', false)" data-testid="closeSidebar">
          <close-svg class="close" width="18px" height="100%" color="#ff8100" />
        </div>
      </div>
    </div>
    <div class="available-items">
      <div
        class="h5"
        v-for="(option, index) in getProductOptions"
        v-if="(!product.errors || Object.keys(product.errors).length === 0) && Object.keys(configuration).length > 0"
        :key="index"
      >
        <div class="row top-xs m0 px15 pt15 pb40 variants-wrapper">
          <p class="my10">
            {{ option.label === 'Color' ? $t('Kolor:') : option.label === 'width' ? $t('Szerokość (mm):') : option.label === 'height' ? $t('Wysokość (mm):') : option.label }}
          </p>
          <div class="sizes">
            <size-selector
              class="mr10 mb10"
              v-for="filter in sortById(getAvailableFilters[option.attribute_code])"
              :key="filter.id"
              :variant="filter"
              :label="filter.label"
              :selected-filters="getSelectedFilters"
              @change="changeFilter"
              :class="{ 'disabled': !isOptionAvailable(filter) || !variantIsInStock(filter.id) }"
            />
          </div>
        </div>
      </div>
      <add-to-cart
        :product="product"
        class="col-xs-6 add-to-cart-btn"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AvailableVariation from '@vue-storefront/core/compatibility/components/AvailableVariation'
import SizeSelector from 'theme/components/core/SizeSelector.vue'
// import RelatedSmall from 'theme/components/core/blocks/Product/RelatedSmall.vue'
// import Product from '@vue-storefront/core/pages/Product'
import AddToCart from 'theme/components/core/AddToCart.vue'
import { isOptionAvailableAsync } from '@vue-storefront/core/modules/catalog/helpers/index'
import { getAvailableFiltersByProduct, getSelectedFiltersByProduct } from '@vue-storefront/core/modules/catalog/helpers/filters'
import { ProductOption } from '@vue-storefront/core/modules/catalog/components/ProductOption.ts'
import CloseSvg from 'theme/components/theme/blocks/Icons/CloseSvg.vue'

export default {
  components: {
    SizeSelector,
    // RelatedSmall,
    AddToCart,
    CloseSvg
  },
  data () {
    return {};
  },
  mixins: [AvailableVariation],
  computed: {
    ...mapGetters({
      getCurrentProduct: 'product/getCurrentProduct',
      getCurrentProductConfiguration: 'product/getCurrentProductConfiguration'
    }),
    getAvailableFilters () {
      return getAvailableFiltersByProduct(this.getCurrentProduct)
    },
    getProductOptions () {
      if (
        this.getCurrentProduct.errors &&
        Object.keys(this.getCurrentProduct.errors).length &&
        Object.keys(this.getCurrentProductConfiguration).length
      ) {
        return []
      }
      return this.getCurrentProduct.configurable_options
    }
  },
  methods: {
    variantIsInStock (id) {
      if (id) {
        let allProducts = this.product.configurable_children
        let product = allProducts.find(product => product.size === Number(id))
        if (product) {
          return product.stock.is_in_stock
        } else {
          return false
        }
      } else {
        return false
      }
    },
    changeFilter (variant) {
      this.$bus.$emit(
        'filter-changed-product',
        Object.assign({ attribute_code: variant.type }, variant)
      )
      this.getQuantity()
    },
    async getQuantity () {
      if (this.isStockInfoLoading) return // stock info is already loading
      this.isStockInfoLoading = true
      try {
        const res = await this.$store.dispatch('stock/check', {
          product: this.getCurrentProduct,
          qty: this.getCurrentProduct.qty
        })
        this.maxQuantity = res.qty
      } finally {
        this.isStockInfoLoading = false
      }
    },
    isOptionAvailable (option) { // check if the option is available
      const currentConfig = Object.assign({}, this.getCurrentProductConfiguration)
      currentConfig[option.type] = option
      return isOptionAvailableAsync(this.$store, { product: this.getCurrentProduct, configuration: currentConfig })
    },
    sortById (filters) {
      return [...filters].sort((a, b) => {
        if (a < b) { return -1 }
        if (a > b) { return 1 }
        return 0
      })
    }
  }
};
</script>

<style lang="scss">
.add-to-cart-btn {
  margin: 5px auto;
}
.variants-wrapper {
  @media (max-width: 767px) {
    padding-bottom: 20px;
  }

 .sizes {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .size-guide {
    height: 40px;
    @media (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
}
.available-variation {
  position: relative;
  height: 100%;
  padding-bottom: 1px;
  .available-items {
    max-height: calc(100vh - 85px);
    overflow: auto;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    padding: 0 8px;
  }
  .title-container {
    border-bottom: 1px solid #eee;
  }
}
</style>
