<template>
  <div
    class="microcart mw-100 fixed"
    :class="[productsInCart.length ? 'bg-cl-primary' : 'bg-cl-primary', { active: isOpen }]"
    data-testid="microcart"
  >
    <div class="row m0 middle-xs top-sm" :class="isMobile ? 'bg-cl-white' : 'bg-cl-alternative'">
      <div class="div-top-text col-xs-12">
        <h2
          v-if="productsInCart.length"
          class="mt30 mb30 align-center ml40 heading uppercase relative"
          :class="isMobile ? 'cl-black' : 'cl-white'"
        >
          {{ $t('Shopping cart') }}
          <button
            type="button"
            class="p0 brdr-none bg-cl-transparent close"
            @click="closeMicrocartExtend"
            data-testid="closeMicrocart"
          >
            <img :src="'/assets/svg/Krzyzyk.svg'" width="24px" height="24px" class="p10" :class="{'filter-gray' : isMobile}" alt="krzyzyk">
          </button>
        </h2>
      </div>
    </div>
    <div class="row m0 middle-xs bg-cl-alternative top-sm" v-if="!productsInCart.length">
      <div class="div-top-text col-xs-12">
        <h2
          class="cl-white mt30 mb30 align-center ml40 heading uppercase relative"
        >
          {{ $t('Your shopping cart is empty.') }}
          <button
            type="button"
            class="p0 brdr-none bg-cl-transparent close"
            @click="closeMicrocartExtend"
            data-testid="closeMicrocart"
          >
            <img :src="'/assets/svg/Krzyzyk.svg'" width="24px" height="24px" class="p10" alt="krzyzyk">
          </button>
        </h2>
      </div>
      <!-- <div class="col-xs-12 col-sm mt35 mb35 mt0 end-sm clearcart-col">
        <clear-cart-button
          class="clearcart"
          v-if="productsInCart.length"
          @click.native="clearCart"
        />
      </div> -->
    </div>
    <div v-if="!productsInCart.length" class="ml30 mt30 empty-text h4" @click="closeMicrocartExtend">
      {{ $t("Don't hesitate and") }}
      <router-link :to="localizedRoute('/')">
        {{ $t('browse our catalog') }}
      </router-link>
      {{ $t('to find something beautiful for You!') }}
    </div>
    <div
      v-if="productsInCart.length && !isEdge"
      class="bg-cl-primary products visible-xs"
      :class="{'withoutInstant': !isInstantCheckoutRegistered}"
    >
      <guide-notification class="visible-xs" />
      <component :is="SwipeActions" :products="productsInCart" />
    </div>
    <ul v-if="productsInCart.length" class="bg-cl-primary m0 pb40 products" :class="{ 'hidden-xs' : !isEdge }">
      <product v-for="product in productsInCart" :key="product.checksum + product.sku" :product="product" class="card-product brdr-bottom-1 brdr-cl-gainsboro" />
    </ul>
    <div class="footer-bottom">
      <div
        v-if="productsInCart.length"
        class="summary px40"
        :class="{'withoutInstant' : !isInstantCheckoutRegistered}"
      >
        <div v-if="customTotals.length > 0" class="row m0 title hidden-xs pt5 pb10">
          <div class="col-sm-12">
            <p class="uppercase h3 cl-black m0">
              {{ $t('Podsumowanie') }}
            </p>
          </div>
        </div>
        <div class="row mt5 mb5 m0 bg-cl-white middle-xs"
             v-if="!isMobile"
             v-for="(segment, index) in customTotals" :key="index"
        >
          <div class="col-xs-6" v-if="segment.title">
            {{ $t(segment.title) }}:
          </div>
          <button v-if="appliedCoupon && segment.code === 'discount'" type="button" class="p0 brdr-none bg-cl-transparent close delete-button ml10" @click="clearCoupon">
            <close-svg width="24px" height="100%" color="#707070" />
          </button>
          <div v-if="segment.value != null" class="col-xs-6 align-right">
            <span class="uppercase cl-orange mr10" v-if="isShippedForFree && segment.code === 'shipping'">
              {{ $t('Darmowa wysyłka') }}
            </span>
            <span :class="{ 'free-shipping' : isShippedForFree && segment.code === 'shipping'}">
              <template v-if="segment.code === 'shipping'">
                {{ Math.round(segment.value * 1.23) | price }}
              </template>
              <template v-else>
                {{ segment.value | price }}
              </template>
            </span>
          </div>
        </div>
        <div class="tooltip bg-cl-dark-blue middle-xs around-xs brdr-bottom-1 brdr-cl-white" v-if="showTooltipDiscount && !isShowDiscount">
          <i class="icon-delivery cl-white fs-medium" />
          <p class="tooltip-text cl-white m0">
            {{ $t('Kup jeszcze za ') }}{{ discountCount.price | price }} {{ $t('i otrzymaj') }} {{ discountCount.value }}{{ $t(' rabatu na całe zamówienie!') }}
          </p>
          <img :src="'/assets/svg/Krzyzyk.svg'" class="pointer krzyzyk-icon" alt="krzyzyk" @click="showTooltipDiscount = false">
        </div>
        <div class="tooltip bg-cl-dark-blue middle-xs around-xs brdr-bottom-1 brdr-cl-white" v-if="showTooltip && !isShippedForFree">
          <i class="icon-delivery cl-white fs-medium" />
          <p class="tooltip-text cl-white m0">
            Czy wiesz, że składając zamówienie powyżej 5000zł otrzymasz dostawę gratis?<br>
            Kup jeszcze na {{ shippedCount | price }} zł a odblokujesz darmową dostawę
          </p>
          <img :src="'/assets/svg/Krzyzyk.svg'" class="pointer krzyzyk-icon" alt="krzyzyk" @click="showTooltip = false">
        </div>
        <div class="row m0 discount-div">
          <div v-if="OnlineOnly && !addCouponPressed" class="col-xs-12">
            <button
              class="p0 brdr-none fs-medium-small bg-cl-transparent ff-light cl-matterhorn weight-400"
              type="button"
              @click="addDiscountCoupon"
            >
              {{ $t('Posiadasz kartę podarunkową/kod rabatowy?') }}
            </button>
          </div>
          <div v-if="OnlineOnly && addCouponPressed" class="col-xs-12 coupon-wrapper ff-light bg-cl-white">
            <div class="coupon-input">
              <base-input type="text" id="couponinput" class="coupon-input" :autofocus="true" v-model.trim="couponCode" @keyup.enter="setCoupon" />
            </div>
            <button-outline class="py6" color="dark" :disabled="!couponCode" @click.native="setCoupon">
              {{ $t('Add discount code') }}
            </button-outline>
          </div>
        </div>
        <div class="row pt5 pb5 m0 middle-xs segment" v-for="(segment, index) in totals" :key="index + segment.code" v-if="segment.code === 'grand_total'">
          <div class="col-xs-12">
            <p class="p title-delivery-price ff-semi mb0">
              <template v-if="!isShowDiscount">
                {{ $t('Kup jeszcze za ') }}{{ discountCount.price | price }} {{ $t('i otrzymaj') }} {{ discountCount.value }}{{ $t(' rabatu na całe zamówienie!') }}
                <div class="discount-tooltip_container inline relative">
                  <i class="icon-info icon-tooltip cl-orange" />
                  <div class="discount-tooltip">
                    <h5 class="bg-cl-alternative p10 m0 align-center cl-white">
                      {{ $t('Discover our discount offer') }}
                    </h5>
                    <div class="row details m0">
                      <div>
                        <p class="cl-orange capitalize">
                          {{ $t('The value of purchases') }}
                        </p>
                        <p><small>{{ $t('from') }}</small> 4000 <small>{{ $t('to') }}</small> 5499zł</p>
                        <p><small>{{ $t('from') }}</small> 5500 <small>{{ $t('to') }}</small> 7499zł</p>
                        <p><small>{{ $t('from') }}</small> 7500 <small>{{ $t('to') }}</small> 9 999zł</p>
                        <p><small>{{ $t('from') }}</small> 10 000 <small>{{ $t('to') }}</small> 14 999zł</p>
                        <p><small>{{ $t('from') }}</small> 15 000 <small>{{ $t('to') }}</small> 19 999zł</p>
                        <p><small>{{ $t('from') }}</small> 20 000zł</p>
                      </div>
                      <div class="align-right">
                        <p class="cl-orange capitalize">
                          {{ $t('Discount') }}
                        </p>
                        <p>1%</p>
                        <p>2%</p>
                        <p>3%</p>
                        <p>4%</p>
                        <p>5%</p>
                        <p>6%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </p>
          </div>
          <div class="col-xs-12">
            <p class="p title-delivery-price ff-semi mb0">
              <template v-if="!isShippedForFree">
                {{ $t('Kup jeszcze na ') }}{{ shippedCount | price }} {{ $t(' a odblokujesz darmową wysyłkę!') }}
              </template>
            </p>
          </div>
          <div class="col-xs-12 between-xs totals-discount row m0 bg-cl-white "
               v-if="totalsDiscount && isMobile"
          >
            <div>
              {{ $t(totalsDiscount.title) }}: <span v-if="totalsDiscount.value != null" class="pl10 align-right ff-semi">{{ totalsDiscount.value | price }}</span>
            </div>
          </div>
          <div class="col-xs-8 h4 total-price-label cl-black ff-medium weight-700">
            {{ $t('Łączna wartość koszyka') }}
          </div>
          <div class="col-xs-4 align-right h2 total-price-value cl-black ff-medium weight-700">
            {{ segment.value | price }}
          </div>
          <div class="col-xs-12 info-label ff-semi visible-xs">
            <p>{{ $t('Sposób wysyłki i dostawy wybierz w następnym kroku') }}</p>
          </div>
        </div>
      </div>

      <div
        class="row m0 py15 px40 middle-xs actions"
        :class="{'withoutInstant' : !isInstantCheckoutRegistered}"
        v-if="productsInCart.length && !isCheckoutMode"
      >
        <div class="col-xs-12 hidden-xs col-md-4">
          <p @click="closeMicrocartExtend" data-testid="closeMicrocart" class="uppercase pointer m0">
            {{ $t('Return to shopping') }}
          </p>
        </div>
        <div class="checkout-but col-xs-12 col-md-8 end-sm">
          <button-full
            :link="{ name: 'checkout' }"
            @click.native="closeMicrocartExtend"
            class=""
          >
            {{ $t('Go to checkout') }}
          </button-full>
        </div>
        <div class="alternative-checkout col-xs-12 col-sm-12 end-sm visible-xs" v-if="isInstantCheckoutRegistered">
          <div>
            <p class="label-1 cl-accent my5">
              {{ $t('lub') }}
            </p>
          </div>
          <no-ssr>
            <instant-checkout />
          </no-ssr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { MobileDetected } from 'theme/components/core/mobileDetected.ts'
import i18n from '@vue-storefront/i18n'
import NoSSR from 'vue-no-ssr'
import { isModuleRegistered } from '@vue-storefront/core/lib/module'
import VueOfflineMixin from 'vue-offline/mixin'
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress'
import InstantCheckout from 'src/modules/vsf-instant-checkout/components/InstantCheckout.vue'
import { registerModule } from '@vue-storefront/core/lib/modules'
import GuideNotification from 'theme/components/core/blocks/Microcart/GuideNotification'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import ClearCartButton from 'theme/components/core/blocks/Microcart/ClearCartButton'
import ButtonFull from 'theme/components/theme/ButtonFull'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import Product from 'theme/components/core/blocks/Microcart/Product'
import { BrowserDetected } from 'theme/components/core/BrowserDetected.ts'
import { InstantCheckoutModule } from 'src/modules/vsf-instant-checkout'
import CloseSvg from 'theme/components/theme/blocks/Icons/CloseSvg.vue'
import config from 'config'

const SwipeActions = () => import(/* webpackChunkName: "SwipeActions" */ 'theme/components/core/blocks/Microcart/SwipeActions.vue')

export default {
  components: {
    Product,
    ClearCartButton,
    ButtonFull,
    ButtonOutline,
    BaseInput,
    InstantCheckout,
    GuideNotification,
    'no-ssr': NoSSR,
    CloseSvg
  },
  mixins: [
    BrowserDetected,
    VueOfflineMixin,
    onEscapePress,
    MobileDetected
  ],
  beforeCreate () {
    registerModule(InstantCheckoutModule)
  },
  data () {
    return {
      showTooltip: true,
      showTooltipDiscount: true,
      SwipeActions,
      addCouponPressed: false,
      couponCode: '',
      componentLoaded: false,
      isInstantCheckoutRegistered: false
      // this.$store.getters['instant-checkout/getSupported']
    }
  },
  props: {
    isCheckoutMode: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.componentLoaded = true
      if (this.isMicrocartOpen) {
        let body = document.getElementsByTagName('body')[0]
        body.classList.add('iphone-scroll')
      }
    })
  },
  destroyed () {
    let body = document.getElementsByTagName('body')[0]
    body.classList.remove('iphone-scroll')
  },
  computed: {
    ...mapState({
      isMicrocartOpen: state => state.ui.microcart
    }),
    ...mapGetters({
      productsInCart: 'cart/getCartItems',
      appliedCoupon: 'cart/getCoupon',
      totals: 'cart/getTotals',
      isOpen: 'cart/getIsMicroCartOpen'
    }),
    customTotals () {
      let temp = this.totals.filter(item => item.code === 'discount')
      if (temp.length > 0) {
        return this.totals.filter(item => item.code === 'discount' || item.code === 'subtotal')
      } else {
        return []
      }
    },
    isShippedForFree () {
      if (this.shippedCount <= 0) {
        return true
      } else {
        return false
      }
    },
    isShowDiscount () {
      return this.discountCount <= 20000
    },
    discountCount () {
      let segment = this.totals.find(x => x.code === 'subtotal')
      if (segment && (segment.code === 'subtotal' || segment.code === 'subtotalInclTax')) {
        if (segment.value <= 4000) {
          return { price: 4000 - segment.value, value: '1%' }
        } else if (segment.value > 4000 && segment.value <= 5500) {
          return { price: 5500 - segment.value, value: '2%' }
        } else if (segment.value > 5500 && segment.value <= 7500) {
          return { price: 7500 - segment.value, value: '3%' }
        } else if (segment.value > 7500 && segment.value <= 10000) {
          return { price: 10000 - segment.value, value: '4%' }
        } else if (segment.value > 10000 && segment.value <= 15000) {
          return { price: 15000 - segment.value, value: '5%' }
        } else if (segment.value > 15000 && segment.value <= 20000) {
          return { price: 20000 - segment.value, value: '6%' }
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    shippedCount () {
      let segment = this.totals.find(x => x.code === 'subtotal')
      if (segment && (segment.code === 'subtotal' || segment.code === 'subtotalInclTax')) {
        return config.server.freeDeliveryValue - segment.value
      } else {
        return config.server.freeDeliveryValue
      }
    },
    totalsDiscount () {
      return this.totals && this.totals.find(x => x.code === 'discount')
    }
  },
  methods: {
    ...mapActions({
      applyCoupon: 'cart/applyCoupon'
    }),
    addDiscountCoupon () {
      this.addCouponPressed = true
    },
    clearCoupon () {
      this.$store.dispatch('cart/removeCoupon')
      this.addCouponPressed = false
    },
    toggleMicrocart () {
      this.$store.dispatch('ui/toggleMicrocart')
    },
    async setCoupon () {
      const couponApplied = await this.applyCoupon(this.couponCode)
      this.addCouponPressed = false
      this.couponCode = ''
      if (!couponApplied) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: i18n.t("You've entered an incorrect coupon code. Please try again."),
          action1: { label: i18n.t('OK') }
        })
      }
    },
    closeMicrocartExtend () {
      this.toggleMicrocart()
      this.$store.commit('ui/setSidebar', false)
      this.addCouponPressed = false
    },
    onEscapePress () {
      this.toggleMicrocart()
    },
    clearCart () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: i18n.t('Are you sure you would like to remove all the items from the shopping cart?'),
        action1: { label: i18n.t('Cancel'), action: 'close' },
        action2: { label: i18n.t('OK'),
          action: async () => {
            await this.$store.dispatch('cart/clear', { recreateAndSyncCart: false }) // just clear the items without sync
            await this.$store.dispatch('cart/sync', { forceClientState: true })
          }
        },
        hasNoTimeout: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~theme/css/animations/transitions";
  .segment {
    @media (max-width: 767px) {
      background-color: white;
    }
  }
  .icon-tooltip {
    font-size: 20px;
    vertical-align: bottom;
  }
  .discount-tooltip_container {
    display: inline-block;
    position: relative;
    border-color: #ff8100;
    padding: 2px 5px;
    border-radius: 50%;
    .discount-tooltip {
      display: none;
      position: absolute;
      height: auto;
      min-width: 300px;
      bottom: 20px;
      right: 18px;
      background-color: #fff;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
      .details {
        padding: 10px;
        background-color: #fff;
        justify-content: space-between;
        div {
          background-color: #fff;
        }
      }
      @media (max-width: 767px) {
        min-width: 220px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    &:hover .discount-tooltip {
      display: block;
    }
  }
  .totals-discount {
    padding: 0px 17px 5px 22px;
  }
  .tooltip {
    display: flex;
    height: 60px;
    opacity: 0.9;
    transition: opacity .25s ease-in-out;
    @media (min-width: 767px) {
      display: none !important;
    }
    @media (max-width: 767px) {
      padding: 0 8px;
    }
    .tooltip-text {
      font-size: 14px;
      @media (max-width: 767px) {
        font-size: 12px;
        padding-left: 8px;
      }
    }
    .krzyzyk-icon {
      width: 24px;
      height: 24px;
      @media (max-width: 767px) {
        width: 18px;
        height: 18px;
      }
    }
  }
  .filter-gray {
    filter: invert(0.5);
  }
  @media (min-width: 768px) {
    .footer-bottom {
      width: 800px;
      position: fixed;
      bottom: 0;
    }
  }
  .discount-div {
    @media (max-width: 767px) {
      background: #fff;
    }
    @media (min-width: 768px) {
      height: 55px;
      div {
        display: flex;
        align-items: flex-end;
        button {
          font-size: 16px;
          color: #707070;
        }
      }
    }
  }
  .total-price-label {
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
  .microcart {
    top: 0;
    right: 0;
    z-index: 3;
    height: 100%;
    width: 800px;
    min-width: 320px;
    overflow-y: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
  }
  .microcart>.products {
    overflow-y: auto;
    scroll-behavior: smooth;
    // position: fixed;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    height: calc(100% - 450px);
    max-height: calc(100% - 450px);
    // width: 800px;
  }

 @media (max-width: 767px) {
    .microcart {
      overflow: hidden;
    }
    .microcart>.top-sm {
      position: fixed;
      width:100%;
      margin: 0;
      top:0px;
      border-bottom: 1px solid #e0e0e0;
    }
    .div-top-text {
      flex-basis: 100%;
      max-width: 100%;
    }
    .div-top-text h2 {
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 15px;
      margin: 15px 0 15px 0px;
    }
    .microcart>.products {
      padding: 0px 0px;
      margin-top:49px;
      width: 100%;
      position: fixed;
      overflow-y: scroll;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      max-height: calc(100% - 295px);
      height: auto;
      &.withoutInstant {
        max-height: calc(100% - 290px);
      }
    }
    .microcart>.footer-bottom>.summary {
      position: fixed;
      bottom: 133px;
      width: 100%;
      left: -15px;
      &.withoutInstant {
       bottom: 60px;
      }
    }
    .discount-div {
      padding-top: 15px !important;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    .discount-div button {
      border: 1px solid #D2D2D2;
      border-radius: 12px;
      width: 100% !important;
      padding: 5px 0;
      font-size: 12px;
      height: auto;
      /* width: 50%; */
      min-width: 0;
    }
    .title-delivery-price {
      font-weight:100;
      margin: 0;
      padding: 3px 15px 4px 15px;
    }
    .info-label p {
      margin: 0;
      padding: 5px 15px 0px 15px;
      font-weight: 100;
      font-size: 12px;
    }
    .microcart>.footer-bottom>.summary .total-price-label {
    padding-left: 22px;
    font-size: 16px !important;
    }
    .microcart>.footer-bottom>.summary .total-price-value {
     font-size: 16px !important;
     padding-right: 25px;
     padding-left: 0px;
    }
    .microcart>.footer-bottom>.actions {
      position: fixed;
      bottom: 0;
      margin: 0;
      width: 100%;
      left: 0;
      padding-bottom: env(safe-area-inset-bottom);
      &.withoutInstant {
        bottom: 10px;
      }
    }
 }
.microcart>.footer-bottom>.summary .total-price-label {
  font-size:28px;
}
.microcart>.footer-bottom>.summary .total-price-value {
  font-size: 28px;
}
.products {
  @media (max-width: 767px) {
    padding: 30px 15px;
  }
}
.empty-text {
  margin-top: 60px;
}
.free-shipping {
  text-decoration: line-through;
  text-decoration-color: #da3800;
}
.discount-div {
  padding-top: 5px;
  padding-left: 0px;
  padding-right: 15px;
}
  // defaults
  .close {
    position: absolute;
    top: 0;
    right: 10px;
    img {
      @media (max-width: 767px) {
        padding: 0;
        width: 18px;
        height: 18px;
      }
    }
  }

  .mt0 {
    @media (max-width: 767px) {
      margin-top: 0;
    }
  }

  .clearcart {
    &-col {
      display: flex;
      align-self: center;
    }
  }

  .products {
    @media (max-width: 767px) {
      padding: 30px 15px;
    }
  }

  .actions {
    background-color: #ededed;
    @media (max-width: 767px) {
      padding: 0 15px;
      // padding-left: 15px;
      // padding-right: 15px;
      // scroll-padding-bottom: 0;
      // padding-bottom: env(safe-area-inset-bottom);
      background-color: transparent;
    }
    .link {
      @media (max-width: 767px) {
        display: flex;
        justify-content: center;
        padding: 20px 70px;
        &.checkout {
          margin-top: 55px;
          padding: 0;
        }
      }
    }
  }

  .summary {
    box-shadow: -1px -5px 6px rgba(0, 0, 0, 0.08);
    @media (min-width: 768px) {
      background-color: #ededed;
      div {
        background-color: #ededed;
      }
    }
    @media (max-width: 767px) {
      box-shadow: none;
      padding:  0 15px;
      font-size: 12px;
    }
  }

  .summary-heading {
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .total-price-value {
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  .delete-button {
    vertical-align: middle;
  }

.coupon-wrapper {
  display: flex;

  .button-outline {
    text-transform: inherit;
    width: 50%;
  }

  .coupon-input {
    margin-right: 20px;
    width: 100%;
    height: 44px;
    min-height: 0;
  }
}
</style>
