<template>
  <!-- <button
    v-if="supported && isApplePayAvailable()"
    @click="showPayment"
  >
    {{ $t('Instant Checkout with ApplePay') }}
  </button>
  <button
    v-else-if="supported && !isApplePayAvailable()"
    @click="showPayment"
  >
    {{ $t('Instant Checkout with GooglePay') }}
  </button> -->
  <button-apple v-if="supported && isApplePayAvailable()" :on-click="instantCheckoutClick" />
  <button-google v-else-if="supported && !isApplePayAvailable()" :on-click="instantCheckoutClick" />
</template>

<script>
import ButtonGoogle from 'theme/components/theme/ButtonGoogle.vue'
import ButtonApple from 'theme/components/theme/ButtonApple.vue'

import i18n from '@vue-storefront/i18n'
import rootStore from '@vue-storefront/core/store'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import config from 'config'
import { getGuaClientId } from 'theme/utils/getGuaClientId'
import { getGuaUa } from 'theme/utils/getGuaUa'
import { KEY } from './../index'
import * as types from './../store/mutation-types'
import { Logger } from '@vue-storefront/core/lib/logger'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { OrderModule } from '@vue-storefront/core/modules/order'
import { storeOrderSummary } from 'theme/helpers/storeOrderSummary'

const storeView = currentStoreView()

export default {
  name: 'InstantCheckoutButton',
  props: {
    product: {
      required: false,
      type: Object,
      default: null
    }
  },
  beforeCreate () {
    registerModule(OrderModule)
  },
  data () {
    return {
      supported: true,
      country: rootStore.state.checkout.shippingDetails.country ? rootStore.state.checkout.shippingDetails.country : storeView.tax.defaultCountry,
      paymentMethods: [
        {
          supportedMethods: 'https://apple.com/apple-pay',
          data: {
            version: 3,
            merchantIdentifier: config.payment_payu.applePay[storeView.storeId].merchantIdentifier,
            merchantCapabilities: ['supports3DS'],
            supportedNetworks: ['masterCard', 'visa'],
            countryCode: config.payment_payu.applePay[storeView.storeId].countryCode,
            currencyCode: config.payment_payu.applePay[storeView.storeId].currencyCode,
            paymentMethodType: 'credit'
          }
        }, {
          supportedMethods: 'https://google.com/pay',
          data: {
            apiVersion: 2,
            apiVersionMinor: 0,
            merchantInfo: {
              merchantId: config.payment_payu.googlePay.merchantId,
              merchantName: config.payment_payu.googlePay.merchantName
            },
            allowedPaymentMethods: [{
              type: 'CARD',
              parameters: {
                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                allowedCardNetworks: ['MASTERCARD', 'VISA']
              },
              tokenizationSpecification: {
                type: 'PAYMENT_GATEWAY',
                parameters: {
                  gateway: config.payment_payu.googlePay.gateway,
                  gatewayMerchantId: config.payment_payu.googlePay.gatewayMerchantId
                }
              }
            }],
            transactionInfo: {},
            environment: config.payment_payu.googlePay.environment
          }
        }
      ],
      paymentOptions: {
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
        requestShipping: true,
        shippingType: 'shipping'
      },
      shippingOptions: []
    }
  },
  components: {
    ButtonGoogle,
    ButtonApple
  },
  computed: {
    platformTotal () {
      return this.$store.state.cart.platformTotalSegments
    },
    bucket () {
      const bucket = []

      // Add products
      this.$store.state.cart.cartItems.forEach(product => {
        bucket.push({
          label: product.name,
          amount: {
            currency: storeView.i18n.currencyCode,
            value: this.getProductPrice(product)
          }
        })
      })

      // If synchronization is disabled add shipping and return
      if (this.platformTotal === null) {
        if (this.selectedShippingOption.length > 0) {
          bucket.push({
            label: i18n.t('Shipping'),
            amount: { currency: storeView.i18n.currencyCode, value: this.selectedShippingOption[0].amount.value }
          })
        }

        return bucket
      }

      // If synchronization is eanbled get shipping and discount values from Magento
      const shipping = this.platformTotal.filter(segment => {
        return segment.code === 'shipping'
      })
      if (shipping.length > 0) {
        bucket.push({
          label: shipping[0].title,
          amount: { currency: storeView.i18n.currencyCode, value: Math.round(Math.abs(shipping[0].value) * 1.23) }
        })
      }

      return bucket
    },
    selectedShippingOption () {
      return this.shippingOptions.filter(option => {
        return option.selected
      })
    },
    total () {
      // If synchronization is disabled calculate totals
      if (this.platformTotal === null) {
        let subtotal = 0

        this.$store.state.cart.cartItems.forEach(product => {
          subtotal += parseFloat(product.price_incl_tax)
        })

        if (this.selectedShippingOption.length > 0) {
          subtotal += parseFloat(this.selectedShippingOption[0].amount.value)
        }

        return {
          label: i18n.t('Grand total'),
          amount: { currency: storeView.i18n.currencyCode, value: subtotal }
        }
      }

      // If synchronization is enabled return total taken from Magento
      const total = this.platformTotal.filter(segment => {
        return segment.code === 'grand_total'
      })
      if (total.length > 0) {
        return {
          label: total[0].title,
          amount: { currency: storeView.i18n.currencyCode, value: total[0].value }
        }
      }

      return {}
    },
    paymentDetails () {
      return {
        displayItems: this.bucket,
        shippingOptions: this.shippingOptions,
        total: this.total
      }
    }
  },
  methods: {
    storeOrderSummary: storeOrderSummary,
    isApplePayAvailable () {
      return window.ApplePaySession
    },
    addTransactionInfoToGooglePayMethod (pos, transactionInfo) {
      this.paymentMethods[1].data.transactionInfo = transactionInfo
    },
    instantCheckoutClick () {
      if (this.product) {
        this.showPayment(new Promise(async (resolve, reject) => {
          const res = await this.$store.dispatch('cart/addItem', { productToAdd: this.product })

          if (res.serverResponses.length === 0) {
            const product = await this.$store.dispatch('cart/getItem', { product: this.product })
            if (product) {
              resolve(this.paymentDetails)
            }
          } else if (!res.serverResponses.filter(response => response.status !== 200).length) {
            resolve(this.paymentDetails)
          } else {
            reject(Error('cart error'))
          }
        }))
      } else {
        this.showPayment()
      }
    },
    showPayment (promise) {
      this.addTransactionInfoToGooglePayMethod(1, {
        currencyCode: this.total.amount.currency,
        totalPrice: this.total.amount.value.toString(),
        totalPriceStatus: 'FINAL'
      })

      // console.log(JSON.stringify(this.paymentMethods))

      const payment = new PaymentRequest(this.paymentMethods, this.paymentDetails, this.paymentOptions)

      // console.log(payment)

      payment.addEventListener('shippingoptionchange', this.shippingOptionChange)
      payment.addEventListener('shippingaddresschange', this.shippingAddressChange)
      payment.onmerchantvalidation = function (event) {
        // console.log(event.validationURL)
        // console.log('fetching ' + config.payment_payu.endpoint.apple_payment_request)

        fetch(config.payment_payu.endpoint.apple_payment_request + '?storeCode=' + storeView.storeCode, {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            url: event.validationURL
          })
        }).then(response => response.json())
          .then(data => {
            // console.log('fetch result: ')
            // console.log(data)
            event.complete(data.result)
          }).catch(error => {
            Logger.log('payment error', error)()
            // console.log('error' + error)
          })
      }
      payment
        .show(promise)
        .then(response => {
          // TODO handle payment
          // console.log(response)

          this.$bus.$off('order-after-placed')
          this.$bus.$on('order-after-placed', this.afterPlaceOrder)

          this.$store.dispatch('order/placeOrder', this.createOrder(response), {root: true}).then(result => {
            if (!result.resultCode || result.resultCode === 200) {
              this.$bus.$emit('test-order-placed')
              response.complete('success')
            } else {
              response.complete('fail')
            }
          })
        })
        .catch(e => {
          // console.log('payment.show() error')
          // console.log(e.name + ' ' + e.message)
        })
    },
    checkPaymentInformation (payload) {
      return fetch((config.payment_payu.endpoint.payment_information).replace('{{ backendOrderId }}',
        payload.confirmation.backendOrderId), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
      })
    },
    afterPlaceOrder (payload) {
      // console.log('afterPlaceOrder')
      // console.log(payload)

      this.checkPaymentInformation(payload)
        .then(response => response.json())
        .then(data => {
          // console.log(data)
          // console.log(payload)

          if (data.result[0].redirectUri) {
            this.$store.commit('ui/setMicrocart', false)
            this.$store.dispatch('cart/clear', { recreateAndSyncCart: true }, {root: true})
            window.location.href = data.result[0].redirectUri
          } else {
            this.$store.dispatch('checkout/setThankYouPage', true)
            this.$store.commit('ui/setMicrocart', false)
            this.$router.push(this.localizedRoute('/checkout'))
            this.$bus.$emit('test-order-placed')
            this.$store.dispatch('cart/clear', { recreateAndSyncCart: true }, {root: true})
            this.$bus.$emit('instant-notification-completed')
          }
        })
    },
    shippingOptionChange (event) {
      const selectedId = event.target.shippingOption

      this.shippingOptions.forEach(option => {
        option.selected = option.id === selectedId
      })

      const dataToUpdate = new Promise((resolve, reject) => {
        this.$store.dispatch('cart/syncTotals', {
          methodsData: {
            country: this.country,
            method_code: this.selectedShippingOption.length > 0 ? this.selectedShippingOption[0].method_code : null,
            carrier_code: this.selectedShippingOption.length > 0 ? this.selectedShippingOption[0].carrier_code : null,
            payment_method: null
          },
          forceServerSync: true
        }).then(() => {
          resolve({
            displayItems: this.bucket,
            shippingOptions: this.shippingOptions,
            total: this.total
          })
        }).catch(e => {
          console.error(e)
          reject(e)
        })
      })

      event.updateWith(dataToUpdate)
    },
    shippingAddressChange (event) {
      const shippingAddress = event.target.shippingAddress
      this.country = shippingAddress.country

      const dataToUpdate = new Promise((resolve, reject) => {
        this.updateShippingOptions(true)
          .then(() => {
            return this.$store.dispatch('cart/syncTotals', {
              methodsData: {
                country: this.country,
                method_code: this.selectedShippingOption.length > 0 ? this.selectedShippingOption[0].method_code : null,
                carrier_code: this.selectedShippingOption.length > 0 ? this.selectedShippingOption[0].carrier_code : null,
                payment_method: null
              },
              forceServerSync: true
            })
          }).then(() => {
            resolve({
              displayItems: this.bucket,
              shippingOptions: this.shippingOptions,
              total: this.total
            })
          }).catch(e => {
            console.error(e)
            reject(e)
          })
      })

      event.updateWith(dataToUpdate)
    },
    updateShippingOptions (setDefault = false) {
      return new Promise((resolve, reject) => {
        rootStore.dispatch('cart/syncShippingMethods', {
          country_id: this.country
        }, { forceServerSync: true }).then(() => {
          this.shippingOptions = []
          this.$store.getters['checkout/getShippingMethods'].forEach(method => {
            if (!config.payment_payu.instant_checkout.shipping.exclude_methods.includes(method.carrier_code)) {
              this.shippingOptions.push({
                id: method.carrier_code,
                method_code: method.method_code,
                carrier_code: method.carrier_code,
                label: method.method_title,
                selected: setDefault ? this.$store.getters['checkout/getShippingMethods'][0].carrier_code === method.carrier_code : false,
                amount: {
                  currency: storeView.i18n.currencyCode,
                  value: Math.round(method.price_incl_tax)
                }
              })
            }
          })
          resolve()
        }).catch(e => {
          console.error(e)
          reject(e)
        })
      })
    },
    createOrder (paymentResponse) {
      // Shipping first name and last name
      const shippingRecipient = paymentResponse.shippingAddress.recipient.split(' ')
      const shippingFirstName = shippingRecipient[0]
      shippingRecipient.shift()
      const shippingLastName = shippingRecipient.join(' ') || i18n.t('(lastname not provided)')

      // Billing first name and last name
      const billingRecipient = paymentResponse.payerName.split(' ')
      const billingFirstName = billingRecipient[0]
      billingRecipient.shift()
      const billingLastName = billingRecipient.join(' ') || i18n.t('(lastname not provided)')

      // console.log(JSON.stringify(paymentResponse.details))

      let paymentMethodAdditional = {}
      let paymentMethodCode = '';

      if (paymentResponse.methodName === 'https://google.com/pay') {
        paymentMethodAdditional = {
          payu_method: 'ap',
          payu_method_type: 'PBL',
          authorization_code: btoa(paymentResponse.details.paymentMethodData.tokenizationData.token),
          base_return_url: config.payment_payu.base_return_url[storeView.storeId]
        }
        paymentMethodCode = 'vue_payu_gateway_googlepay'
      } else if (paymentResponse.methodName === 'https://apple.com/apple-pay') {
        paymentMethodAdditional = {
          payu_method: 'jp',
          payu_method_type: 'PBL',
          authorization_code: btoa(JSON.stringify(paymentResponse.details.token.paymentData))
        }
        paymentMethodCode = 'vue_payu_gateway_applepay'
      }

      paymentMethodAdditional['gua_client_id'] = getGuaClientId()

      const orderData = {
        user_id: this.$store.state.user.current ? this.$store.state.user.current.id.toString() : '',
        cart_id: this.$store.state.cart.cartServerToken ? this.$store.state.cart.cartServerToken : '',
        products: this.$store.state.cart.cartItems,
        addressInformation: {
          shippingAddress: {
            region: '',
            region_id: 0,
            country_id: paymentResponse.shippingAddress.country,
            street: [paymentResponse.shippingAddress.addressLine[0], paymentResponse.shippingAddress.addressLine[1]],
            company: paymentResponse.shippingAddress.organization ? paymentResponse.shippingAddress.organization : '',
            telephone: paymentResponse.shippingAddress.phone,
            postcode: paymentResponse.shippingAddress.postalCode,
            city: paymentResponse.shippingAddress.city,
            firstname: shippingFirstName,
            lastname: shippingLastName,
            email: paymentResponse.payerEmail,
            region_code: paymentResponse.shippingAddress.region ? paymentResponse.shippingAddress.region : ''
          },
          billingAddress: {
            region: '',
            region_id: 0,
            country_id: paymentResponse.shippingAddress.country,
            street: [paymentResponse.shippingAddress.addressLine[0], paymentResponse.shippingAddress.addressLine[1]],
            company: paymentResponse.shippingAddress.organization ? paymentResponse.shippingAddress.organization : '',
            telephone: paymentResponse.payerPhone,
            postcode: paymentResponse.shippingAddress.postalCode,
            city: paymentResponse.shippingAddress.city,
            firstname: billingFirstName,
            lastname: billingLastName,
            email: paymentResponse.payerEmail,
            region_code: paymentResponse.shippingAddress.region ? paymentResponse.shippingAddress.region : '',
            vat_id: ''
          },
          shipping_method_code: this.selectedShippingOption[0].method_code,
          shipping_carrier_code: this.selectedShippingOption[0].carrier_code,
          payment_method_code: paymentMethodCode,
          payment_method_additional: paymentMethodAdditional
        }
      }
      this.storeOrderSummary(paymentResponse)
      return orderData
    },
    getProductPrice (product) {
      if (!config.cart.displayItemDiscounts) {
        return product.qty * product.price_incl_tax
      }

      if (product.totals) {
        if (product.totals.discount_amount) {
          return product.totals.row_total_incl_tax - product.totals.discount_amount
        } else {
          return product.totals.row_total_incl_tax
        }
      }

      return product.regular_price * product.qty
    }
  },
  mounted () {
    if (window.PaymentRequest && window.ApplePaySession) {
      this.supported = true
      this.updateShippingOptions()
    }
  }
}
</script>
