import { mapGetters } from 'vuex'
import { isOptionAvailableAsync } from '@vue-storefront/core/modules/catalog/helpers/index'

export default {
  name: 'AvailableVariation',
  computed: {
    ...mapGetters({
      product: 'product/productCurrent'
    })
  },
  methods: {
    isOptionAvailableWithConfig (color, size) { // check if the option is available
      let currentConfig = { color: color, rozmiar: size }
      return isOptionAvailableAsync(this.$store, { product: this.product, configuration: currentConfig })
    }
  }
}
