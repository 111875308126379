<template>
  <transition name="fade" appear>
    <div class="guide w-100 cl-tertiary" v-if="isOpen">
      <div class="container">
        <div class="row between-xs middle-xs px15">
          <div class="col-xs-12 start-xs">
            <img src="assets/swipe-koszyk-streetsupply.gif" alt="assets/swipeGuide.gif" width="100%">
            <span class="guide-text">{{ $t('Przesuń w lewo, aby zmienić ilość lub usunąć produkt.') }}</span>
            <div
              class="icom p10 pointer close-guide"
              @click="accept"
              @keyup.enter="accept"
              data-testid="closeguideButton"
              tabindex="0"
              role="button"
            >
              <close-svg width="18px" height="100%" color="#da3800" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CloseSvg from 'theme/components/theme/blocks/Icons/CloseSvg.vue'

export default {
  components: {
    CloseSvg
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    accept () {
      this.setVisited()
      this.isOpen = false
    },
    setVisited () {
      this.$store.dispatch('claims/set', { claimCode: 'guideAccepted', value: true })
    }
  },
  created () {
    this.$store.dispatch('claims/check', { claimCode: 'guideAccepted' }).then((guideClaim) => {
      if (!guideClaim) {
        this.isOpen = true
        this.$store.dispatch('claims/set', { claimCode: 'guideAccepted', value: false })
      } else {
        this.isOpen = !guideClaim.value
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-icon: color(black);
$bg-icon: color(suva-gray);

.guide {
  .container {
    display: flex;
    align-items: center;
    height: 89px;
    color: #da3800;
    background-color: #f1f1f16e;
  }
}

.icon:hover {
  color: $color-icon;
  background-color: $bg-icon;
}
.close-guide {
  position: absolute;
  top: 0;
  right: 0;
}
.guide-text {
  position: absolute;
  top: 22px;
  right: 20px;
  max-width: 150px;
  font-size: 12px;
  @media (max-width: 767px) {
    top: 35px;
    right: 0px;
    font-size: 10px;
  }
}
</style>
