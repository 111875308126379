<template>
  <div class="choose-variation bg-cl-primary p0">
    <div class="row">
      <div class="col-xs-12 center-xs title-container p10">
        <span class="title py5 cl-primary-orange">Dodaj do koszyka</span>
        <button
          type="button"
          class="p0 brdr-none bg-cl-transparent close"
          @click="$store.commit('ui/setAddToCart', false)"
          data-testid="closeSidebar"
        >
          <close-svg width="18px" height="100%" color="#ff8100" />
        </button>
      </div>
    </div>
    <div class="row">
      <div class="dynamic-tabs" v-for="(option, indexNav) in getProductOptions" :key="indexNav + 'tabs'">
        <span class="tab-title" @click="navigate(indexNav)">{{ option.label === 'Color' ? 'Kolor' : option.label === 'Szerokość (mm)' ? 'Szerokość' : option.label === "Wysokość (mm)" ? 'Wysokość' : option.label }}</span>
      </div>
    </div>
    <div class="row variation-carousel">
      <div class="col-xs-12">
        <carousel
          :per-page="1"
          pagination-active-color="#ff8100"
          pagination-color="rgba(0,0,0,.2)"
          :navigate-to="1"
          ref="carousel"
          id="carousel"
        >
          <slide v-for="(option, index) in getProductOptions" :key="index">
            <div class="product-colors row">
              <div class="col-md-7">
                <div class="colors py30 start-xs">
                  <size-selector
                    class="mr10 mb10"
                    v-for="filter in sortById(getAvailableFilters[option.attribute_code])"
                    :key="filter.id"
                    :variant="filter"
                    :label="filter.label"
                    :selected-filters="getSelectedFilters"
                    @change="changeFilter"
                    :class="{ 'disabled': !isOptionAvailable(filter) || !variantIsInStock(filter.id) }"
                  />
                </div>
              </div>
            </div>
          </slide>
          <!-- <slide :key="1">
            <div class="product-sizes row">
              <div class="col-md-7">
                <div class="sizes center-xs py30"
                     v-for="(option, index) in product.configurable_options"
                     v-if="(!product.errors || Object.keys(product.errors).length === 0) && Object.keys(configuration).length > 0"
                     :key="index"
                >
                  <size-selector
                    v-for="(c, i) in options[option.attribute_code]"
                    :key="i"
                    :id="c.id"
                    :label="c.label"
                    context="product"
                    :code="option.attribute_code"
                    :class="{ active: c.id == configuration[option.attribute_code].id }"
                    v-focus-clean
                  />
                </div>
              </div>
            </div>
          </slide> -->
        </carousel>
      </div>
    </div>
    <div class="row button-container cl-black m0">
      <div v-if="product.options" class="col-xs-6 left-xs flex">
        <small v-for="(option, index) in selectedOptions(product.options)" class="m0 mb5 cl-primary" :key="index + 'option'">
          {{ option.label === 'color' ? $t('Wybrany kolor:') : option.label === 'width' ? $t('Szerokość (mm):') : option.label === 'height' ? $t('Wysokość (mm):') : option.label }}
          <span class="pl5">{{ option.value }}</span>
        </small>
        <!-- <small class="pb5">Wybrane</small>
        <small>Wymiary: <span>{{ product.options[0].value }}</span></small> -->
        <!-- <small class="chosen">Wybrany kolor: {{ configuration.color.label }}</small>
        <small class="chosen">Wybrany rozmiar: {{ configuration.rozmiar.label }}</small> -->
      </div>
      <div class="col-xs-6 flex">
        <div class="row py5 middle-xs center-xs">
          <p class="m0">
            Ilość: {{ product.qty }}
          </p>
          <i class="count-action px5 mx10" @click="decreaseValue()">&#8722;</i>
          <i class="count-action px5 pb1" @click="increaseValue()">&#x2b;</i>
        </div>
        <add-to-cart class="add-to-cart-mobile-variation" :product="product" />
      </div>
    </div>
  </div>
</template>

<script>
import AddToCart from 'theme/components/core/AddToCart.vue';
import { mapGetters, mapState } from 'vuex';
import AvailableVariation from '@vue-storefront/core/compatibility/components/AvailableVariation'
import Product from '@vue-storefront/core/compatibility/components/blocks/Microcart/Product'
import { ProductOption } from '@vue-storefront/core/modules/catalog/components/ProductOption'
import { getAvailableFiltersByProduct, getSelectedFiltersByProduct } from '@vue-storefront/core/modules/catalog/helpers/filters'
import { isOptionAvailableAsync } from '@vue-storefront/core/modules/catalog/helpers/index'
import SizeSelector from 'theme/components/core/SizeSelector.vue'
import CloseSvg from 'theme/components/theme/blocks/Icons/CloseSvg.vue'
// import RelatedSmall from 'theme/components/core/blocks/Product/RelatedSmall.vue'

export default {
  data () {
    return {
      currentSlide: 1,
      productCount: 1
    };
  },
  components: {
    Carousel: () => import('vue-carousel').then(Slider => Slider.Carousel),
    Slide: () => import('vue-carousel').then(Slider => Slider.Slide),
    SizeSelector,
    AddToCart,
    CloseSvg
    // RelatedSmall
  },
  mixins: [Product, ProductOption, AvailableVariation],
  computed: {
    ...mapState({
      product: state => state.product.current,
      options: state => state.product.current_options,
      configuration: state => state.product.current_configuration
    }),
    ...mapGetters({
      getCurrentProduct: 'product/getCurrentProduct',
      getCurrentProductConfiguration: 'product/getCurrentProductConfiguration'
    }),
    getAvailableFilters () {
      return getAvailableFiltersByProduct(this.getCurrentProduct)
    },
    getProductOptions () {
      if (
        this.getCurrentProduct.errors &&
        Object.keys(this.getCurrentProduct.errors).length &&
        Object.keys(this.getCurrentProductConfiguration).length
      ) {
        return []
      }
      return this.getCurrentProduct.configurable_options
    }
  },
  methods: {
    variantIsInStock (id) {
      if (id) {
        let allProducts = this.product.configurable_children
        let product = allProducts.find(product => product.size === Number(id))
        if (product) {
          return product.stock.is_in_stock
        } else {
          return false
        }
      } else {
        return false
      }
    },
    sortById (filters) {
      return [...filters].sort((a, b) => {
        if (a < b) { return -1 }
        if (a > b) { return 1 }
        return 0
      })
    },
    isOptionAvailable (option) { // check if the option is available
      const currentConfig = Object.assign({}, this.getCurrentProductConfiguration)
      currentConfig[option.type] = option
      return isOptionAvailableAsync(this.$store, { product: this.getCurrentProduct, configuration: currentConfig })
    },
    changeFilter (variant) {
      this.$bus.$emit(
        'filter-changed-product',
        Object.assign({ attribute_code: variant.type }, variant)
      )
      this.getQuantity()
    },
    async getQuantity () {
      if (this.isStockInfoLoading) return // stock info is already loading
      this.isStockInfoLoading = true
      try {
        const res = await this.$store.dispatch('stock/check', {
          product: this.getCurrentProduct,
          qty: this.getCurrentProduct.qty
        })
        this.maxQuantity = res.qty
      } finally {
        this.isStockInfoLoading = false
      }
    },
    selectedOptions (options) {
      if (Array.isArray(options)) {
        let arr = []
        options.map(opt => {
          return arr.push(opt)
        })
        return arr
      }
    },
    navigate (index) {
      this.$refs.carousel.goToPage(index);
    },
    decreaseValue () {
      this.product.qty < 2 ? this.product.qty = 1 : this.product.qty -= 1
    },
    increaseValue () {
      this.product.qty += 1
    }
  }
};
</script>

<style lang="scss">
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-border: color(silver);

.choose-variation {
  position: relative;
  height: 100%;
  .close {
    position: absolute;
    right: 0;
    top: -1px;
    height: 40px;
    padding: 0 8px;
  }
  .dynamic-tabs {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
  .title {
    text-transform: uppercase;
  }
  .title-container {
    border-bottom: 1px solid #eee;
  }
  .tab-title {
    text-transform: uppercase;
    color: #000;
    padding: 10px;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
  }
  .VueCarousel,
  .VueCarousel-wrapper,
  .VueCarousel-inner,
  .product-colors,
  .product-sizes {
    height: 100%;
  }
  .VueCarousel-dot-container {
    margin: 0 !important;
    display: flex !important;
    flex-basis: 0;
    flex-grow: 1;
  }
  .VueCarousel-pagination {
    position: absolute;
    top: 0px;
    height: 0;
  }
  .VueCarousel-navigation-button {
    margin: 0;
    transform: translateY(-50%) !important;
  }
  .VueCarousel-slide {
    backface-visibility: unset;
  }
  .VueCarousel-navigation {
    opacity: 0;
    &--disabled {
      display: none;
    }
  }
  .VueCarousel-dot {
    padding: 0px !important;
    width: 100% !important;
    height: 5px;
    display: inherit;
    margin: 0 !important;
    border-radius: 0 !important;
    button {
      border: 0 !important;
      border-radius: 0 !important;
      width: 100% !important;
      height: 5px !important;
      outline: none !important;
      display: block;
    }
  }
  .variation-carousel {
    min-height: calc(40vh - 130px);
    .colors {
      height: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      box-sizing: border-box;
      padding: 30px 20px 100px 20px;
      overflow: auto;
      flex-wrap: wrap;
      min-height: 150px;
      max-height: 70vh;
    }
    .sizes {
      height: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 30px 20px 100px 20px;
      overflow: auto;
      flex-wrap: wrap;
      min-height: 150px;
      max-height: 70vh;
      justify-content: flex-start;
      width: fit-content;
      button {
        margin-left: 10px;
        margin-bottom: 10px;
      }
    }
  }
  .button-container {
    padding: 8px;
    position: absolute;
    width: 100%;
    bottom: 0;
    border-top: 1px solid #eee;
    background: #fff;
    .add-to-cart-mobile-variation {
      min-width: 175px;
      padding-right: 0;
    }
    .count-action {
      border: 1px solid $color-border;
      border-radius: 50%;
      font-size: 24px;
      font-style: normal;
    }
    .chosen {
      display: inline-block;
      width: 100%;
    }
    .flex {
      flex-direction: column;
      justify-content: center;
    }
  }
  .sizes-label {
    font-size: 0.8em;
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    padding: 5px 15px;
    border-radius: 15px;
    top: -40px;
    left: 8px;
    border: 0;
  }
}
</style>
