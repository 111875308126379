export const BrowserDetected = {
  name: 'BrowserDetected',
  components: {
  },
  data () {
    return {
      browserName: ''
    }
  },
  mounted () {
    this.setBrowserName()
  },
  methods: {
    setBrowserName () {
      this.browserName = window.navigator.userAgent
    }
  },
  computed: {
    isEdge () {
      if (this.browserName.indexOf('Edge') !== -1) {
        return true
      } else {
        return false
      }
    }
  }
}
