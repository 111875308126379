<template>
  <button
    class="google-button-black brdr-none w-100"
    @click="onClick"
  >
    <span class="text">{{ $t('Kup, używając') }}</span>
    <span class="logo" />
  </button>
</template>

<script>
import focusClean from 'theme/components/theme/directives/focusClean'
export default {
  name: 'ButtonGoogle',
  directives: { focusClean },
  props: {
    onClick: {
      type: Function,
      required: false,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
    .google-button-black {
        font-weight: 400;
        background-color: black;
        display: inline-flex;
        justify-content: center;
        font-size: 18px;
        border-radius: 30px;
        padding: 12px 0;
        box-sizing: border-box;
        min-width: 200px;
        min-height: 32px;
        max-height: 64px;
    }
    .google-button-black > .text {
        color: white;
        align-self: center;
    }
    .google-button-black > .logo {
        width: 32px;
        margin-left: 10px;
        background: url("/assets/google-pay-mark.png") 0 0 no-repeat;
        height: 20px;
        background-size: cover;
        border: none;
    }
</style>
