<template>
  <li class="row py10 bg-cl-white m0">
    <div class="blend">
      <div class="ml20">
        <product-image class="brdr-1 brdr-cl-gainsboro" :image="image" />
      </div>
    </div>
    <div class="col-xs pt10 flex pl15 flex-wrap">
      <div class="flex flex-nowrap details relative">
        <div class="flex col-sm-8 flex-wrap between-xs">
          <div>
            <router-link
              class="serif h4 name cl-black weight-700 uppercase"
              :to="productLink"
              data-testid="productLink"
              @click.native="$store.commit('ui/setMicrocart', false)"
            >
              {{ product.name | htmlDecode }}
            </router-link>
            <div class="h5 pt5 options" v-if="isTotalsActive">
              <div v-for="opt in product.totals.options" :key="opt.label">
                <span class="opn">{{ $t(opt.label) }}: </span>
                <span class="opv">
                  {{ opt.value }}
                </span>
              </div>
              <p v-if="product.delivery_time <= 3 || isEcommerceProduct" class="m0 fs-medium-small cl-light-gray pt5">Dostępność: <span class="cl-forest-green"> w magazynie</span></p>
              <p v-else class="m0 fs-medium-small cl-light-gray pt5">{{ $t('Czas realizacji') }}: {{ product.delivery_time }} dni</p>
            </div>
            <div class="h5 pt5 options" v-else-if="product.options">
              <div v-for="opt in product.options" :key="opt.label">
                <span class="opn">{{ $t(opt.label) }}: </span>
                <span class="opv">
                  {{ opt.value }}
                </span>
              </div>
              <p v-if="product.delivery_time <= 3 || isEcommerceProduct" class="m0 fs-medium-small cl-light-gray pt5">Dostępność: <span class="cl-forest-green"> w magazynie</span></p>
              <p v-else class="m0 fs-medium-small cl-light-gray pt5">{{ $t('Czas realizacji') }}: {{ product.delivery_time }} dni</p>
            </div>
            <div class="h6 pt5 cl-error" v-if="hasProductErrors">
              {{ product.errors | formatProductMessages }}
            </div>
          </div>
        </div>
        <remove-button class="mx5 absolute right5" @click="removeItem" />
      </div>
      <div class="w-100 flex middle-xs actions">
        <div class="flex col-sm-4 align-right start-xs between-sm quantity">
          <product-quantity
            class="h5 cl-accent qty"
            v-if="product.type_id !== 'grouped' && product.type_id !== 'bundle'"
            :value="productQty"
            :max-quantity="maxQuantity"
            :loading="isStockInfoLoading"
            :is-simple-or-configurable="isSimpleOrConfigurable"
            @input="updateProductQty"
            @click="updateProductQty"
            @error="handleQuantityError"
          />
        </div>
        <div class="prices 1">
          <span class="h3 serif cl-error price-special" v-if="(price.special && price.default && price.original) && price.special !== price.default">
            {{ price.special | price(storeView) }}
          </span>
          <span class="h3 serif price-original" v-if="(price.special && price.default) && price.special !== price.default">
            {{ price.default | price(storeView) }}
          </span>
          <span class="h3 serif price-regular" v-else>
            {{ price.default | price(storeView) }}
          </span>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import Product from '@vue-storefront/core/compatibility/components/blocks/Microcart/Product'
import ProductQuantity from 'theme/components/core/ProductQuantity.vue'
import ProductImage from 'theme/components/core/ProductImage'
import RemoveButton from './RemoveButton'
import { onlineHelper, getValueFor } from '@vue-storefront/core/helpers'
import { ProductOption } from '@vue-storefront/core/modules/catalog/components/ProductOption'
import { getThumbnailForProduct, getProductConfiguration } from '@vue-storefront/core/modules/cart/helpers'
import ButtonFull from 'theme/components/theme/ButtonFull'
import { getCustomOptionValues, getCustomOptionPriceDelta } from '@vue-storefront/core/modules/catalog/helpers/customOption'
import { getBundleOptionsValues, getBundleOptionPrice } from '@vue-storefront/core/modules/catalog/helpers/bundleOptions'
import get from 'lodash-es/get'

export default {
  data () {
    return {
      maxQuantity: 0,
      quantityError: false,
      isStockInfoLoading: false
    }
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  components: {
    RemoveButton,
    ProductImage,
    ButtonFull,
    ProductQuantity
  },
  mixins: [Product, ProductOption],
  computed: {
    ...mapState({
      isMicrocartOpen: state => state.ui.microcart
    }),
    getCurrentCustomOptions () {
      let custom_options = this.product.product_option.extension_attributes.custom_options
        ? this.product.product_option.extension_attributes.custom_options
        : null
      if (!custom_options) return {}
      let currentCustomOptions = {}
      for (let option of custom_options) {
        currentCustomOptions[option.option_id] = option
      }
      return custom_options
    },
    bundleOptionsPrice () {
      const allBundeOptions = this.product.bundle_options || []
      const selectedBundleOptions = Object.values(get(this.product, 'product_option.extension_attributes.bundle_options', {}))
      const price = getBundleOptionPrice(
        getBundleOptionsValues(selectedBundleOptions, allBundeOptions)
      )
      return price
    },
    customOptionsPriceDelta () {
      const priceDelta = this.product.product_option.extension_attributes && this.product.product_option.extension_attributes.custom_options
        ? getCustomOptionPriceDelta(getCustomOptionValues(Object.values(this.product.product_option.extension_attributes.custom_options), this.product.custom_options), this.product)
        : getCustomOptionPriceDelta(getCustomOptionValues(Object.values(this.getCurrentCustomOptions), this.product.custom_options), this.product)

      return priceDelta
    },
    isEcommerceProduct () {
      return this.product.stock && this.product.ecommerce
        ? (this.product.ecommerce === getValueFor('ecommerce', 'yes') && this.product.stock.qty > 0) || (this.product.ecommerce === getValueFor('ecommerce', 'both') && this.product.stock.qty > 0)
        : false
    },
    price () {
      const discountAmount = this.product.totals && this.product.totals.discount_amount
      const customOptionPrice = this.customOptionsPriceDelta.priceInclTax
      const special = discountAmount
        ? (this.initialPrice.default + customOptionPrice) * this.product.qty - discountAmount
        : (this.initialPrice.default + customOptionPrice) * this.product.qty
      const original = (this.initialPrice.original + customOptionPrice) * this.product.qty
      const defaultPrice = this.product.qty > 0
        ? (this.initialPrice.default + customOptionPrice) * this.product.qty
        : this.initialPrice.default

      if (this.bundleOptionsPrice.priceInclTax > 0) {
        return {
          special,
          original,
          default: this.bundleOptionsPrice.priceInclTax
        }
      }

      return {
        special,
        original,
        default: defaultPrice
      }
    },
    initialPrice () {
      return {
        default: this.product.priceInclTax || 0,
        original: this.product.originalPriceInclTax || 0,
        special: this.product.specialPrice || 0
      }
    },
    storeView () {
      return currentStoreView()
    },
    hasProductInfo () {
      return this.product.info && Object.keys(this.product.info).length > 0
    },
    hasProductErrors () {
      return this.product.errors && Object.keys(this.product.errors).length > 0
    },
    isTotalsActive () {
      return this.isOnline && this.product.totals && this.product.totals.options
    },
    isOnline () {
      return onlineHelper.isOnline
    },
    productsAreReconfigurable () {
      return config.cart.productsAreReconfigurable && this.product.type_id === 'configurable' && this.isOnline
    },
    displayItemDiscounts () {
      return config.cart.displayItemDiscounts
    },
    image () {
      return {
        loading: this.thumbnail,
        src: this.thumbnail
      }
    },
    thumbnail () {
      return getThumbnailForProduct(this.product)
    },
    configuration () {
      return getProductConfiguration(this.product)
    },
    productLink () {
      return formatProductLink(this.product, currentStoreView().storeCode)
    },
    productQty () {
      return this.product.qty
    },
    isSimpleOrConfigurable () {
      return ['simple', 'configurable'].includes(this.product.type_id)
    },
    isUpdateCartDisabled () {
      return this.quantityError ||
        this.isStockInfoLoading ||
        (this.isOnline && !this.maxQuantity && this.isSimpleOrConfigurable)
    }
  },
  methods: {
    sortById (filters) {
      return [...filters].sort((a, b) => {
        if (a.label < b.label) { return -1 }
        if (a.label > b.label) { return 1 }
        return 0
      })
    },
    updateProductQty (qty) {
      this.updateQuantity(qty)
    },
    removeFromCart () {
      this.$store.dispatch('cart/removeItem', { product: this.product })
    },
    updateQuantity (quantity) {
      this.$store.dispatch('cart/updateQuantity', { product: this.product, qty: quantity })
    },
    async getQuantity (product) {
      if (this.isStockInfoLoading) return // stock info is already loading
      this.isStockInfoLoading = true
      try {
        const validProduct = product || this.product
        const res = await this.$store.dispatch('stock/check', {
          product: validProduct,
          qty: this.productQty
        })
        return res.qty
      } finally {
        this.isStockInfoLoading = false
      }
    },
    handleQuantityError (error) {
      this.quantityError = error
    }
  },
  watch: {
    isOnline: {
      async handler (isOnline) {
        if (isOnline) {
          const maxQuantity = await this.getQuantity()
          this.maxQuantity = maxQuantity
        }
      }
    },
    isMicrocartOpen: {
      async handler (isOpen) {
        if (isOpen) {
          const maxQuantity = await this.getQuantity()
          this.maxQuantity = maxQuantity
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
  .right5 {
    right: 5px;
  }
  .blend {
    flex: 0 0 150px;
  }
  .image {
    mix-blend-mode: multiply;
    vertical-align: top;
    width: 150px;
    @media (max-width: 767px) {
      width: 100px;
    }
  }
  .details {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row wrap;
  }
  .name {
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .options, .sku {
    @media (max-width: 767px) {
      font-size: 10px;
    }
  }
  .qty {
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
  // .actions {
  //   margin: 0 -5px;
  // }
  .prices {
    margin-left: auto;
    margin-right: 20px;
    flex-direction: column;
    @media (max-width: 767px) {
      padding: 0;
      font-size: 12px;
    }
  }
  .quantity {
    margin-left: auto;
    flex-direction: column;
    @media (max-width: 767px) {
      padding: 0;
      font-size: 12px;
    }
  }
  .price-special {
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .price-original {
    text-decoration: line-through;
  }
  input {
    width: 30px;
  }
  .flex-nowrap {
    flex-wrap: nowrap;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .edit-mode {
    border-bottom: 1px solid color(white-smoke);
  }
  .filters {
    flex: 1 1 200px;
  }
  .update-button {
    font-size: 14px;
    min-width: 150px;
    width: 150px;
    padding: 10px;
  }
</style>
